import {
  LOADING,
  SETPROBLEM,
  SETPROBLEMERROR,
  SAVEPROBLEM,
  SAVEPROBLEMERROR,
  EDITPROBLEM,
  EDITPROBLEMERROR
} from "../constants.js";
import API from "../api";

const getProblems = () => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.get("/problems");

  if (response.status === 200) {
    dispatch({
      type: SETPROBLEM,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETPROBLEMERROR,
      payload: null
    });
  }
};

const saveProblem = (name, parent) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.post("/problems", {
    name,
    parent
  });

  if (response.status === 200) {
    dispatch(getProblems());
    dispatch({
      type: SAVEPROBLEM,
      payload: response.data
    });
  } else {
    dispatch({
      type: SAVEPROBLEMERROR,
      payload: false
    });
  }
};

const editProblem = (problemId, name, parent) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.put(`/problems/${problemId}`, {
    name,
    parent
  });

  if (response.status === 200) {
    dispatch(getProblems());
    dispatch({
      type: EDITPROBLEM,
      payload: response.data
    });
  } else {
    dispatch({
      type: EDITPROBLEMERROR,
      payload: false
    });
  }
};

export default {
  getProblems,
  saveProblem,
  editProblem
};
