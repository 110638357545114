import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import style from "./index.module.css";

function ButtonBack({ children, link }) {
  return (
    <Link to={link} className={style["button__back"]} rel="noopener">
      {children}
    </Link>
  );
}

ButtonBack.propTypes = {
  children: PropTypes.string.isRequired
};

export default ButtonBack;
