import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import style from "./index.module.css";

import { HeaderAdmin, Sidebar } from "../../modules";

import { Auth } from "../../utils";

import { actionComplaint } from "../../redux/actions";

import moment from "moment";

class AdminIndex extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history, complaintList } = props;

    this.state = {
      page: 1
    };

    if (!Auth.checkAuth(tokenData)) {
      history.replace("/admin");
    }

    if (complaintList === null) {
      props.getComplaints(this.state.page);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.props.getComplaints(this.state.page);
    }
  }

  getComplaintList = () => {
    const { complaintList } = this.props;

    let complaintListElm = [];
    if (complaintList) {
      if (complaintList.length) {
        complaintListElm = complaintList.map(item => {
          const momentDate = moment(item.datetime);

          return (
            <tr key={item.complaintId}>
              <td>{item.name}</td>
              <td>{item.noab}</td>
              <td>{momentDate.format("DD/MM/YYYY")}</td>
              <td>
                <Link to={"/admin/problemas/" + item.complaintId}>
                  Ver Detalhes
                </Link>
              </td>
            </tr>
          );
        });
      } else {
        complaintListElm.push(
          <tr key="1">
            <td colSpan="4">Nenhuma reclamação encontrada</td>
          </tr>
        );
      }
    }

    return complaintListElm;
  };

  handlePage = handler => {
    this.setState(prevState => ({
      page: prevState.page + handler
    }));
  };

  getNavigationButton = () => {
    const { page } = this.state;
    const { complaintList } = this.props;

    let navigationButtonElm = null;

    if (complaintList) {
      const buttonBack =
        page > 1 ? (
          <div
            className={style["buttonLink"]}
            onClick={() => this.handlePage(-1)}
          >
            {"«"}
          </div>
        ) : (
          <div />
        );
      const buttonNext = complaintList.length ? (
        <div className={style["buttonLink"]} onClick={() => this.handlePage(1)}>
          {"»"}
        </div>
      ) : null;

      navigationButtonElm = (
        <div className={style["navigation"]}>
          {buttonBack}
          {buttonNext}
        </div>
      );
    }

    return navigationButtonElm;
  };

  render() {
    const { tokenData } = this.props;
    const complaintListRows = this.getComplaintList();

    const navigationButton = this.getNavigationButton();

    let exportLink = "";
    if (window.location.hostname === "localhost") {
      exportLink = "http://156.0.0.3";
    }

    exportLink += "/api/reports/export?token=" + tokenData.token;
    return (
      <>
        <HeaderAdmin />
        <div className={style["page__holder"]}>
          <Sidebar />
          <div className={style["content"]}>
            <table border="0">
              <thead>
                <tr>
                  <th>Advogado</th>
                  <th>OAB Nº</th>
                  <th>Data da Reclamação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>{complaintListRows}</tbody>
            </table>
            {navigationButton}
            <br />
            <br />
            <a
              href={exportLink}
              className={style["buttonLink"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Baixar Dados em .CSV
            </a>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    complaintList: state.complaint.list
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators({ ...actionComplaint }, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(AdminIndex));
