import {
  LOADING,
  SETCOMPLAINT,
  SETCOMPLAINTERROR,
  SAVECOMPLAINT,
  SAVECOMPLAINTERROR,
  EDITCOMPLAINT,
  EDITCOMPLAINTERROR,
  CLEARCOMPLAINT,
  SETCOMPLAINTBYID,
  SETCOMPLAINTBYIDERROR,
  CLEARCOMPLAINTBYID
} from "../constants.js";
import API from "../api";

const getComplaints = (page = null) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  let query = "";

  if (page) {
    query = "?pageLimit=100&page=" + page;
  }

  const api = new API(token);

  const response = await api.get("/complaints" + query);

  if (response.status === 200) {
    dispatch({
      type: SETCOMPLAINT,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETCOMPLAINTERROR,
      payload: null
    });
  }
};

const getComplaintsById = id => async (dispatch, getState) => {
  if (id) {
    dispatch({ type: LOADING });

    const reduxState = getState();
    const token = reduxState.login.tokenData
      ? reduxState.login.tokenData.token
      : null;

    const api = new API(token);

    const response = await api.get("/complaints/" + id);

    if (response.status === 200) {
      dispatch({
        type: SETCOMPLAINTBYID,
        payload: response.data
      });
    } else {
      dispatch({
        type: SETCOMPLAINTBYIDERROR,
        payload: null
      });
    }
  }
};

const clearComplaint = () => dispatch => {
  dispatch({ type: CLEARCOMPLAINT });
};

const clearComplaintById = () => dispatch => {
  dispatch({ type: CLEARCOMPLAINTBYID });
};

const saveComplaint = data => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.post("/complaints", data);

  if (response.status === 200) {
    dispatch({
      type: SAVECOMPLAINT,
      payload: response.data
    });
  } else {
    dispatch({
      type: SAVECOMPLAINTERROR,
      payload: false
    });
  }
};

const editComplaint = (categoryId, name, parent) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.put(`/complaints/${categoryId}`, {
    name,
    parent
  });

  if (response.status === 200) {
    dispatch(getComplaints());
    dispatch({
      type: EDITCOMPLAINT,
      payload: response.data
    });
  } else {
    dispatch({
      type: EDITCOMPLAINTERROR,
      payload: false
    });
  }
};

export default {
  getComplaints,
  saveComplaint,
  editComplaint,
  clearComplaint,
  getComplaintsById,
  clearComplaintById
};
