import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { FormLogin, Header } from "../../modules";

import { Auth } from "../../utils";

class AdminLogin extends PureComponent {
  componentDidUpdate(prevProps) {
    const { tokenData, history } = this.props;

    if (Auth.checkAuth(tokenData)) {
      history.replace("/admin/inicio");
    }
  }

  render() {
    return (
      <>
        <div className={style["form__holder"]}>
          <Header title="Login" />
          <br />
          <FormLogin submitLabel="Entrar" admin={true} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData
  };
};

const mapDisptachToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(AdminLogin));
