import {
  SETREPORTNUM,
  SETREPORTNUMERROR,
  SETREPORTCATNUM,
  SETREPORTCATNUMERROR,
  SETREPORTCATNUMLEVEL,
  SETREPORTCATNUMLEVELERROR,
  SETREPORTBYTYPE,
  SETREPORTBYTYPEERROR,
  SETREPORTBYDAY,
  SETREPORTBYDAYERROR,
  SETREPORTBYLOCATION,
  SETREPORTBYLOCATIONERROR
} from "../constants.js";

import { combineReducers } from "redux";

const numbers = (state = {}, { type, payload }) => {
  switch (type) {
    case SETREPORTNUM:
    case SETREPORTNUMERROR:
      return payload;
    default:
      return state;
  }
};

const categoryNumbers = (state = [], { type, payload }) => {
  switch (type) {
    case SETREPORTCATNUM:
    case SETREPORTCATNUMERROR:
      return payload;
    default:
      return state;
  }
};

const categoryLevel = (state = [], { type, payload }) => {
  switch (type) {
    case SETREPORTCATNUMLEVEL:
    case SETREPORTCATNUMLEVELERROR:
      return payload;
    default:
      return state;
  }
};

const categoryByType = (state = [], { type, payload }) => {
  switch (type) {
    case SETREPORTBYTYPE:
    case SETREPORTBYTYPEERROR:
      return payload;
    default:
      return state;
  }
};

const categoryByDay = (state = [], { type, payload }) => {
  switch (type) {
    case SETREPORTBYDAY:
    case SETREPORTBYDAYERROR:
      return payload;
    default:
      return state;
  }
};

const categoryByLocation = (state = [], { type, payload }) => {
  switch (type) {
    case SETREPORTBYLOCATION:
    case SETREPORTBYLOCATIONERROR:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({
  numbers,
  categoryNumbers,
  categoryLevel,
  categoryByType,
  categoryByDay,
  categoryByLocation
});
