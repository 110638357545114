import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import style from "./index.module.css";

function ButtonLink({ children, link, action }) {
  let content = null;

  if (link) {
    const linkRegex = /^http(s)?:\/\/.+$/i;
    if (linkRegex.test(link)) {
      content = (
        <a
          href={link}
          className={style["button"]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    } else {
      content = (
        <Link to={link} className={style["button"]} rel="noopener">
          {children}
        </Link>
      );
    }
  } else if (action) {
    content = (
      <div onClick={action} className={style["button"]}>
        {children}
      </div>
    );
  }

  return content;
}

ButtonLink.propTypes = {
  children: PropTypes.string.isRequired
};

export default ButtonLink;
