import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import style from "./index.module.css";

class Textarea extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: ""
    };
  }

  setValue = e => {
    const value = e.target.value;

    this.setState({
      value
    });

    if (this.props.getValue) {
      this.props.getValue(value);
    }
  };

  render() {
    const { placeholder } = this.props;

    return (
      <div className={style["input__holder"]}>
        <textarea
          className={style["input__field"]}
          placeholder={placeholder}
          value={this.value}
          onChange={this.setValue}
        />
      </div>
    );
  }
}

Textarea.propTypes = {
  placeholder: PropTypes.string
};

export default Textarea;
