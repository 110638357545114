import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { HeaderAdmin, Sidebar } from "../../modules";

import { Auth, Type } from "../../utils";

import { actionCategory, actionComplaint } from "../../redux/actions";

import moment from "moment";

class AdminDetails extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history, categoryList, match } = props;

    if (!Auth.checkAuth(tokenData)) {
      history.replace("/admin");
    }

    if (categoryList === null) {
      props.getCategories();
    }

    const complaintId = match.params.complaintId;

    props.getComplaintsById(complaintId);
  }

  getCagetoryTree = categoryId => {
    const { categoryList } = this.props;
    const getCategoryInArray = [];

    if (categoryId && categoryList) {
      const putCategoryOnArray = categoryIdItem => {
        const categoryItem = categoryList.find(
          item => item.categoryId === categoryIdItem
        );

        getCategoryInArray.unshift(categoryItem.name);

        const parentId = categoryItem.parent ? categoryItem.parent : "0";

        if (parentId !== "0") {
          putCategoryOnArray(parentId);
        }
      };

      putCategoryOnArray(categoryId);
    }

    return getCategoryInArray.join(" > ");
  };

  render() {
    const { complaintItem } = this.props;
    const complaintData = complaintItem;

    let complaintDate = null;

    if (complaintData.datetime) {
      const dateMoment = moment(complaintData.datetime);

      complaintDate = dateMoment.format("DD/MM/YYYY");
    }
    return (
      <>
        <HeaderAdmin />
        <div className={style["page__holder"]}>
          <Sidebar />
          <div className={style["content"]}>
            <p>
              Reclamação de{" "}
              <strong>
                {complaintData.name} (OAB Nº {complaintData.noab})
              </strong>{" "}
              feita em <strong>{complaintDate}</strong>.
            </p>
            <p>Detalhes da reclamação:</p>
            <p>Órgão: {this.getCagetoryTree(complaintData.categoryId)}</p>
            {/*<p>Tipo de Reclamação: {Type.getNameById(complaintData.type)}</p>*/}
            <p>Localidade: {complaintData.city}</p>
            {/*p>Problema: {complaintData.problem}</p>*/}
            <p>
              Observações:
              <br />
              <br /> {complaintData.description}
            </p>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    categoryList: state.category.list,
    complaintItem: state.complaint.item
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators({ ...actionComplaint, ...actionCategory }, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(AdminDetails));
