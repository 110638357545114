import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { HeaderAdmin, Sidebar } from "../../modules";

import { actionCategory } from "../../redux/actions";

import { Auth } from "../../utils";

class AdminCategory extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history, categoryList } = props;

    if (!Auth.checkAuth(tokenData)) {
      history.replace("/admin");
    } else if (categoryList === null) {
      props.getCategories();
    }

    this.state = {
      inputName: "",
      inputParent: "",
      categoryEdit: null
    };
  }

  setValue2State = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  saveCategory = () => {
    const { inputName, inputParent } = this.state;

    if (inputName) {
      const params = {
        name: inputName,
        parent: inputParent ? inputParent : null
      };

      this.props.saveCategory(params.name, params.parent);

      this.setState({
        inputName: "",
        inputParent: ""
      });
    }
  };

  editCategory = () => {
    const { inputName, inputParent, categoryEdit } = this.state;

    if (inputName) {
      const params = {
        categoryId: categoryEdit,
        name: inputName,
        parent: inputParent ? inputParent : "0"
      };

      this.props.editCategory(params.categoryId, params.name, params.parent);

      this.setState({
        inputName: "",
        inputParent: "",
        categoryEdit: null
      });
    }
  };

  startEditCategory = categoryId => {
    const { categoryList } = this.props;

    const categoryEdit = categoryList.find(
      item => item.categoryId === categoryId
    );

    this.setState({
      inputName: categoryEdit.name,
      inputParent: categoryEdit.parent ? categoryEdit.parent : "",
      categoryEdit: categoryEdit.categoryId
    });
  };

  getCategoryListElm = () => {
    const { categoryList } = this.props;

    let categoryListHierarchy = [];

    if (categoryList) {
      const byParent = {};
      categoryList.forEach(item => {
        const parentNumber = item.parent ? item.parent : 0;

        if (!byParent[parentNumber]) {
          byParent[parentNumber] = [];
        }

        byParent[parentNumber].push(item);
      });

      const addChildElm = (parentId, level) => {
        if (byParent[parentId]) {
          const dash = level ? (
            <>
              <span>{String("_").repeat(level)}</span>{" "}
            </>
          ) : null;

          byParent[parentId].forEach(item => {
            categoryListHierarchy.push(
              <tr key={item.categoryId}>
                <td>
                  {dash}
                  {item.name}
                </td>
                <td>
                  <div onClick={() => this.startEditCategory(item.categoryId)}>
                    Editar
                  </div>
                </td>
              </tr>
            );

            addChildElm(item.categoryId, level + 1);
          });
        }
      };

      addChildElm(0, 0);
    }

    return categoryListHierarchy;
  };

  clearEditCategory = () => {
    this.setState({
      inputName: "",
      inputParent: "",
      categoryEdit: null
    });
  };

  render() {
    const { categoryList } = this.props;
    const { inputName, inputParent, categoryEdit } = this.state;

    const categoryTableData = this.getCategoryListElm();

    let categoryOption = null;

    if (categoryList) {
      categoryOption = categoryList.map(item => {
        return (
          <option key={item.categoryId} value={item.categoryId}>
            {item.name}
          </option>
        );
      });
    }

    const buttonAction = categoryEdit ? this.editCategory : this.saveCategory;
    const buttonText = categoryEdit ? "Editar Órgão" : "Criar Órgão";
    const buttonCancel = categoryEdit ? (
      <button className={style["button"]} onClick={this.clearEditCategory}>
        Cancelar
      </button>
    ) : null;

    return (
      <>
        <HeaderAdmin />
        <div className={style["page__holder"]}>
          <Sidebar />
          <div className={style["content"]}>
            <div className={style["input__holder"]}>
              <label>Cadastrar Órgão:</label>
              <input
                type="text"
                onChange={e => this.setValue2State("inputName", e.target.value)}
                value={inputName}
              ></input>
            </div>
            <div className={style["input__holder"]}>
              <label>Categoria pai:</label>
              <select
                onChange={e =>
                  this.setValue2State("inputParent", e.target.value)
                }
                value={inputParent}
              >
                <option value="">Nenhuma</option>
                {categoryOption}
              </select>
            </div>
            <button className={style["button"]} onClick={buttonAction}>
              {buttonText}
            </button>
            {buttonCancel}
            <hr />
            <table border="0">
              <thead>
                <tr>
                  <th>Categorias</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>{categoryTableData}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    categoryList: state.category.list
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators(
    {
      ...actionCategory
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(AdminCategory));
