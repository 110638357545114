import {
  LOADING,
  SETTOKEN,
  ERRORTOKEN,
  SETOABTOKEN,
  SETOABTOKENERROR
} from "../constants.js";
import API from "../api";

const getToken = (email, pass) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const api = new API();

  const loginResponse = await api.post("/login", {
    email,
    pass
  });

  if (loginResponse.status === 200) {
    dispatch({
      type: SETTOKEN,
      payload: loginResponse.data
    });
  } else {
    dispatch({
      type: ERRORTOKEN,
      payload: null
    });
  }
};

const clearToken = () => (dispatch, getState) => {
  dispatch({
    type: SETTOKEN,
    payload: {}
  });
};

const getTokenOAB = (noab, pass) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const api = new API();

  const loginResponse = await api.post("/loginOAB", {
    noab,
    pass
  });

  if (loginResponse.status === 200) {
    dispatch({
      type: SETOABTOKEN,
      payload: loginResponse.data
    });
  } else {
    dispatch({
      type: SETOABTOKENERROR,
      payload: null
    });
  }
};

export default {
  getToken,
  getTokenOAB,
  clearToken
};
