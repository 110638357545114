import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { Header } from "../../modules";
import { ButtonLink, ButtonBack } from "../../components";

import { Auth } from "../../utils";

import { actionComplaint } from "../../redux/actions";

class Erro extends PureComponent {
  constructor(props) {
    super(props);

    const { complaintSaved, tokenData, history } = props;

    if (!Auth.checkAuth(tokenData, 1)) {
      history.replace("/");
    } else if (complaintSaved === null) {
      history.replace("/inicio");
    }
  }

  componentDidUpdate(prevProps) {
    const { complaintSaved, history } = this.props;

    if (complaintSaved === null) {
      history.replace("/inicio");
    }
  }

  back2home = () => {
    this.props.clearComplaint();
  };

  render() {
    const { userInfo } = this.props;
    const title = "ERRO\nINTERNO";
    return (
      <>
        <Header title={title} />
        <p className={style["content"]}>
          Prezado Dr. {userInfo.name},
          <br />
          <br />
          Não foi possível registrar o seu problema.
          <br />
          <br />
          Por favor, tente novamente.
        </p>
        <ButtonLink action={this.back2home}>Tentar novamente</ButtonLink>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    complaintSaved: state.complaint.saved,
    userInfo: state.user.info
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators({ ...actionComplaint }, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(Erro));
