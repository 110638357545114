import { LOADING } from "../constants.js";

import { combineReducers } from "redux";

const loading = (state = false, { type, payload }) => {
  switch (type) {
    case LOADING:
      return true;
    default:
      return false;
  }
};

export default combineReducers({ loading });
