import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { HeaderAdmin, Sidebar } from "../../modules";

import { actionProblem } from "../../redux/actions";

import { Auth, Type } from "../../utils";

class AdminProblem extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history, problemList } = props;

    if (!Auth.checkAuth(tokenData)) {
      history.replace("/admin");
    } else if (problemList === null) {
      props.getProblems();
    }

    this.state = {
      inputName: "",
      inputParent: "falha",
      problemEdit: null
    };
  }

  setValue2State = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  saveProblem = () => {
    const { inputName, inputParent } = this.state;

    if (inputName) {
      const params = {
        name: inputName,
        parent: inputParent
      };

      this.props.saveProblem(params.name, params.parent);

      this.setState({
        inputName: "",
        inputParent: ""
      });
    }
  };

  editProblem = () => {
    const { inputName, inputParent, problemEdit } = this.state;

    if (inputName) {
      const params = {
        problemId: problemEdit,
        name: inputName,
        parent: inputParent
      };

      this.props.editProblem(params.problemId, params.name, params.parent);

      this.setState({
        inputName: "",
        inputParent: "",
        problemEdit: null
      });
    }
  };

  clearEditProblem = () => {
    this.setState({
      inputName: "",
      inputParent: "",
      problemEdit: null
    });
  };

  startEditProblem = problemId => {
    const { problemList } = this.props;

    const problemEdit = problemList.find(item => item.problemId === problemId);

    this.setState({
      inputName: problemEdit.name,
      inputParent: problemEdit.parent,
      problemEdit: problemEdit.problemId
    });
  };

  render() {
    const { inputName, problemEdit, inputParent } = this.state;
    const { problemList } = this.props;

    const buttonAction = problemEdit ? this.editProblem : this.saveProblem;
    const buttonText = problemEdit ? "Editar Problema" : "Cadastrar Problema";
    const buttonCancel = problemEdit ? (
      <button className={style["button"]} onClick={this.clearEditProblem}>
        Cancelar
      </button>
    ) : null;

    let problemListElm = null;

    if (problemList) {
      problemListElm = problemList.map(item => {
        return (
          <tr key={item.problemId}>
            <td>{item.name}</td>
            <td>
              <div onClick={() => this.startEditProblem(item.problemId)}>
                Editar
              </div>
            </td>
          </tr>
        );
      });
    }

    let typeList = Type.getList();
    let typeListElm = typeList.map((item, index) => {
      return (
        <option key={index} value={item.name}>
          {item.value}
        </option>
      );
    });

    return (
      <>
        <HeaderAdmin />
        <div className={style["page__holder"]}>
          <Sidebar />
          <div className={style["content"]}>
            <div className={style["input__holder"]}>
              <label>Cadastrar Problema:</label>
              <input
                type="text"
                onChange={e => this.setValue2State("inputName", e.target.value)}
                value={inputName}
              ></input>
            </div>
            <div className={style["input__holder"]}>
              <label>Tipo:</label>
              <select
                value={inputParent}
                onChange={e =>
                  this.setValue2State("inputParent", e.target.value)
                }
              >
                {typeListElm}
              </select>
            </div>
            <button className={style["button"]} onClick={buttonAction}>
              {buttonText}
            </button>
            {buttonCancel}
            <hr />
            <table border="0">
              <thead>
                <tr>
                  <th>Tipos de Problema</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>{problemListElm}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators(
    {
      ...actionProblem
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(AdminProblem));
