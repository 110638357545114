import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { FormLogin, Header } from "../../modules";

import { Auth } from "../../utils";

class Login extends PureComponent {
  componentDidUpdate(prevProps) {
    const { tokenData, history } = this.props;

    if (Auth.checkAuth(tokenData, 1)) {
      history.replace("/inicio");
    }
  }

  render() {
    return (
      <>
        <Header title="Login" />
        <p className={style["subtitle"]}>
          Utilize os mesmos dados de acesso da Área Restrita da OAB.
        </p>
        <FormLogin submitLabel="Entrar" admin={false} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData
  };
};

const mapDisptachToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(Login));
