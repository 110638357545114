import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import style from "./index.module.css";

import { HeaderAdmin, Sidebar } from "../../modules";

import { actionReport } from "../../redux/actions";

import { Auth, Type } from "../../utils";

import { Line, Bar } from "react-chartjs-2";

class AdminReport extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history } = props;

    if (!Auth.checkAuth(tokenData)) {
      history.replace("/admin");
    }

    this.state = {
      period: ""
    };

    this.getReports();
  }

  getReports = (period = "") => {
    this.props.getReportNum(period);
    this.props.getReportCatNum(period);
    this.props.getReportCatNumLevel(period);
    this.props.getReportByType(period);
    this.props.getReportByDay(period);
    this.props.getReportByLocation(period);
  };

  setPeriod = value => {
    this.getReports(value);

    this.setState({
      period: value
    });
  };

  clearPeriod = () => {
    this.setState({
      period: ""
    });
  };

  getCategoryListElm = () => {
    const { reportsCatNum } = this.props;

    let categoryListHierarchy = [];

    if (reportsCatNum) {
      const byParent = {};
      reportsCatNum.forEach(item => {
        const parentNumber = item.parent ? item.parent : 0;

        if (!byParent[parentNumber]) {
          byParent[parentNumber] = [];
        }

        byParent[parentNumber].push(item);
      });

      const addChildElm = (parentId, level) => {
        if (byParent[parentId]) {
          byParent[parentId].forEach(item => {
            let trStyle = level === 0 ? style["parent"] : null;
            categoryListHierarchy.push(
              <tr key={item.categoryId} className={trStyle}>
                <td>{item.name}</td>
                <td>{item.cases}</td>
              </tr>
            );

            addChildElm(item.categoryId, level + 1);
          });
        }
      };

      addChildElm(0, 0);
    }

    return categoryListHierarchy;
  };

  getLineData = type => {
    const { reportsByDay } = this.props;

    const labelArr = [];
    const dataArr = [];

    for (let date in reportsByDay) {
      labelArr.unshift(date);
      dataArr.unshift(reportsByDay[date][type]);
    }

    const dataLine = {
      labels: labelArr,
      datasets: [
        {
          label: "Reclamações",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "#fff",
          borderColor: "#d71920",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#efefef",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#d71920",
          pointHoverBorderColor: "#efefef",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataArr
        }
      ]
    };

    return dataLine;
  };

  getColumnData = level => {
    const { reportsCatNumLevel } = this.props;

    const labelArr = [];
    const dataArr = [];

    if (reportsCatNumLevel[level]) {
      reportsCatNumLevel[level].forEach(item => {
        labelArr.push(item.name);
        dataArr.push(item.cases);
      });
    }

    const dataBar = {
      labels: labelArr,
      datasets: [
        {
          label: "Reclamações",
          backgroundColor: "#d71920",
          borderColor: "#efefef",
          borderWidth: 1,
          hoverBackgroundColor: "#d71920",
          hoverBorderColor: "#efefef",
          data: dataArr
        }
      ]
    };

    return dataBar;
  };

  getReportByLocation = data => {
    const reportByLocations = {};

    for (let cityName in data) {
      reportByLocations[cityName] = data[cityName].length;
    }

    const reportByLocationsSortedNames = Object.keys(reportByLocations).sort(
      function(a, b) {
        return reportByLocations[b] - reportByLocations[a];
      }
    );

    const reportByLocationsSorted = {};

    reportByLocationsSortedNames.forEach(cityName => {
      reportByLocationsSorted[cityName] = data[cityName].length;
    });

    return reportByLocationsSorted;
  };

  render() {
    const {
      reportsNum,
      reportsByType,
      tokenData,
      reportsByLocation,
      reportsCatNumLevel
    } = this.props;
    const { period } = this.state;

    const buttonCancel = period ? (
      <button className={style["button"]} onClick={this.clearPeriod}>
        Cancelar
      </button>
    ) : null;

    const complaintByCatElm = this.getCategoryListElm();

    const reportsByTypeElm = {};

    const reportGraphLines = [];

    for (let name in reportsByType) {
      reportsByTypeElm[name] = [];
      reportsByType[name].forEach(item => {
        if (reportsByTypeElm[name][item.problem]) {
          reportsByTypeElm[name][item.problem]++;
        } else {
          reportsByTypeElm[name][item.problem] = 1;
        }
      });

      reportGraphLines.push(
        <div key={name} className={style["chart__wrapper"]}>
          <h2>{Type.getNameById(name)} por Dia</h2>
          <Line data={this.getLineData(name)} />
        </div>
      );
    }

    const reportByTypeTable = [];

    for (let name in reportsByTypeElm) {
      const reportByTypeTableItem = [];
      for (let problemName in reportsByTypeElm[name]) {
        reportByTypeTableItem.push(
          <tr key={problemName}>
            <td>{problemName}</td>
            <td>{reportsByTypeElm[name][problemName]}</td>
          </tr>
        );
      }
      reportByTypeTable.push(
        <div key={name} className={style["table__wrapper"]}>
          <table>
            <thead>
              <tr>
                <th>{Type.getNameById(name)}</th>
                <th>Reclamações</th>
              </tr>
            </thead>
            <tbody>{reportByTypeTableItem}</tbody>
          </table>
        </div>
      );
    }

    const tableLocationItens = [];

    const reportsByLocationData = this.getReportByLocation(reportsByLocation);
    for (var cityName in reportsByLocationData) {
      tableLocationItens.push(
        <tr key={cityName}>
          <td>{cityName || "Indefinida"}</td>
          <td>{reportsByLocationData[cityName]}</td>
        </tr>
      );
    }

    const tableLocation = (
      <div className={style["table__wrapper"]}>
        <table>
          <thead>
            <tr>
              <th>Localização</th>
              <th>Reclamações</th>
            </tr>
          </thead>
          <tbody>{tableLocationItens}</tbody>
        </table>
      </div>
    );

    let exportLink = "";
    if (window.location.hostname === "localhost") {
      exportLink = "http://156.0.0.3";
    }

    exportLink += "/api/reports/export?token=" + tokenData.token;

    if (period) {
      exportLink += "?period=" + period;
    }

    const barOption = {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0
            }
          }
        ]
      }
    };

    const boxElm = [];

    if (reportsCatNumLevel[1]) {
      reportsCatNumLevel[1].forEach(item => {
        boxElm.push(
          <div key={item.name} className={style["card"]}>
            <h3 className={style["card__label"]}>
              Total de Reclamações
              <br />
              {item.name}
            </h3>
            <span>{item.cases}</span>
          </div>
        );
      });
    }

    return (
      <>
        <HeaderAdmin />
        <div className={style["page__holder"]}>
          <Sidebar />
          <div className={style["content"]}>
            <div className={style["input__holder"]}>
              <label>Selecione o período:</label>
              <select
                value={period}
                onChange={e => this.setPeriod(e.target.value)}
              >
                <option value="">Todo o período</option>
                <option value="week">Últimos 7 dias</option>
                <option value="month">Últimos 30 dias</option>
                <option value="year">Último ano</option>
              </select>
            </div>
            {buttonCancel}
            <hr />
            <div className={style["cards__list"]}>{boxElm}</div>
            <div className={style["table__wrapper"]}>
              <table>
                <thead>
                  <tr>
                    <th>Categoria</th>
                    <th>Reclamações</th>
                  </tr>
                </thead>
                <tbody>{complaintByCatElm}</tbody>
              </table>
            </div>

            {reportByTypeTable}

            {tableLocation}

            <div className={style["charts"]}>
              {reportGraphLines}

              <div className={style["chart__wrapper"]}>
                <h2>Por Categoria</h2>
                <Bar data={this.getColumnData(1)} options={barOption} />
              </div>
              <div className={style["chart__wrapper"]}>
                <h2>Por Sub-Categoria</h2>
                <Bar data={this.getColumnData(2)} options={barOption} />
              </div>
            </div>
            <br />
            <a
              href={exportLink}
              className={style["buttonLink"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Baixar Dados em .CSV
            </a>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    reportsNum: state.reports.numbers,
    reportsCatNum: state.reports.categoryNumbers,
    reportsCatNumLevel: state.reports.categoryLevel,
    reportsByType: state.reports.categoryByType,
    reportsByDay: state.reports.categoryByDay,
    reportsByLocation: state.reports.categoryByLocation
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators(
    {
      ...actionReport
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(AdminReport));
