import {
  LOADING,
  SETREPORTNUM,
  SETREPORTNUMERROR,
  SETREPORTCATNUM,
  SETREPORTCATNUMERROR,
  SETREPORTCATNUMLEVEL,
  SETREPORTCATNUMLEVELERROR,
  SETREPORTBYTYPE,
  SETREPORTBYTYPEERROR,
  SETREPORTBYDAY,
  SETREPORTBYDAYERROR,
  SETREPORTBYLOCATION,
  SETREPORTBYLOCATIONERROR
} from "../constants.js";
import API from "../api";

const getReportNum = period => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  let url = "/reports/numbers";

  if (period) {
    url += "?period=" + period;
  }

  const response = await api.get(url);

  if (response.status === 200) {
    dispatch({
      type: SETREPORTNUM,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETREPORTNUMERROR,
      payload: null
    });
  }
};

const getReportCatNum = period => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  let url = "/reports/numberbyCategory";

  if (period) {
    url += "?period=" + period;
  }

  const response = await api.get(url);

  if (response.status === 200) {
    dispatch({
      type: SETREPORTCATNUM,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETREPORTCATNUMERROR,
      payload: null
    });
  }
};

const getReportCatNumLevel = period => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  let url = "/reports/numberbyCategoryLevel";

  if (period) {
    url += "?period=" + period;
  }

  const response = await api.get(url);

  if (response.status === 200) {
    dispatch({
      type: SETREPORTCATNUMLEVEL,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETREPORTCATNUMLEVELERROR,
      payload: null
    });
  }
};

const getReportByType = period => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  let url = "/reports/complaintByType";

  if (period) {
    url += "?period=" + period;
  }

  const response = await api.get(url);

  if (response.status === 200) {
    dispatch({
      type: SETREPORTBYTYPE,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETREPORTBYTYPEERROR,
      payload: null
    });
  }
};

const getReportByDay = period => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  let url = "/reports/complaintByDay";

  if (period) {
    url += "?period=" + period;
  }

  const response = await api.get(url);

  if (response.status === 200) {
    dispatch({
      type: SETREPORTBYDAY,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETREPORTBYDAYERROR,
      payload: null
    });
  }
};

const getReportByLocation = period => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  let url = "/reports/complaintByLocation";

  if (period) {
    url += "?period=" + period;
  }

  const response = await api.get(url);

  if (response.status === 200) {
    dispatch({
      type: SETREPORTBYLOCATION,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETREPORTBYLOCATIONERROR,
      payload: null
    });
  }
};

export default {
  getReportNum,
  getReportCatNum,
  getReportByType,
  getReportByDay,
  getReportCatNumLevel,
  getReportByLocation
};
