const LOADING = "LOADING";

const SETTOKEN = "SETTOKEN";
const ERRORTOKEN = "ERRORTOKEN";
const SETOABTOKEN = "SETOABTOKEN";
const SETOABTOKENERROR = "SETOABTOKENERROR";

const SETCATEGORY = "SETCATEGORY";
const SETCATEGORYERROR = "SETCATEGORYERROR";
const SAVECATEGORY = "SAVECATEGORY";
const SAVECATEGORYERROR = "SAVECATEGORYERROR";
const EDITCATEGORY = "EDITCATEGORY";
const EDITCATEGORYERROR = "EDITCATEGORYERROR";

const SETPROBLEM = "SETPROBLEM";
const SETPROBLEMERROR = "SETPROBLEMERROR";
const SAVEPROBLEM = "SAVEPROBLEM";
const SAVEPROBLEMERROR = "SAVEPROBLEMERROR";
const EDITPROBLEM = "EDITPROBLEM";
const EDITPROBLEMERROR = "EDITPROBLEMERROR";

const SETCOMPLAINT = "SETCOMPLAINT";
const SETCOMPLAINTERROR = "SETCOMPLAINTERROR";
const SAVECOMPLAINT = "SAVECOMPLAINT";
const SAVECOMPLAINTERROR = "SAVECOMPLAINTERROR";
const EDITCOMPLAINT = "EDITCOMPLAINT";
const EDITCOMPLAINTERROR = "EDITCOMPLAINTERROR";
const CLEARCOMPLAINT = "CLEARCOMPLAINT";
const SETCOMPLAINTBYID = "SETCOMPLAINTBYID";
const SETCOMPLAINTBYIDERROR = "SETCOMPLAINTBYIDERROR";
const CLEARCOMPLAINTBYID = "CLEARCOMPLAINTBYID";

const SETREPORTNUM = "SETREPORTNUM";
const SETREPORTNUMERROR = "SETREPORTNUMERROR";
const SETREPORTCATNUM = "SETREPORTCATNUM";
const SETREPORTCATNUMERROR = "SETREPORTCATNUMERROR";
const SETREPORTCATNUMLEVEL = "SETREPORTCATNUMLEVEL";
const SETREPORTCATNUMLEVELERROR = "SETREPORTCATNUMLEVELERROR";
const SETREPORTBYTYPE = "SETREPORTBYTYPE";
const SETREPORTBYTYPEERROR = "SETREPORTBYTYPEERROR";
const SETREPORTBYDAY = "SETREPORTBYDAY";
const SETREPORTBYDAYERROR = "SETREPORTBYDAYERROR";
const SETREPORTBYLOCATION = "SETREPORTBYLOCATION";
const SETREPORTBYLOCATIONERROR = "SETREPORTBYLOCATIONERROR";

const SETCITY = "SETCITY";
const SETCITYERROR = "SETCITYERROR";

export {
  LOADING,
  SETTOKEN,
  ERRORTOKEN,
  SETOABTOKEN,
  SETOABTOKENERROR,
  SETCATEGORY,
  SETCATEGORYERROR,
  SAVECATEGORY,
  SAVECATEGORYERROR,
  EDITCATEGORY,
  EDITCATEGORYERROR,
  SETPROBLEM,
  SETPROBLEMERROR,
  SAVEPROBLEM,
  SAVEPROBLEMERROR,
  EDITPROBLEM,
  EDITPROBLEMERROR,
  SETCOMPLAINT,
  SETCOMPLAINTERROR,
  SETCOMPLAINTBYID,
  SETCOMPLAINTBYIDERROR,
  CLEARCOMPLAINTBYID,
  SAVECOMPLAINT,
  SAVECOMPLAINTERROR,
  EDITCOMPLAINT,
  EDITCOMPLAINTERROR,
  CLEARCOMPLAINT,
  SETREPORTNUM,
  SETREPORTNUMERROR,
  SETREPORTCATNUM,
  SETREPORTCATNUMERROR,
  SETREPORTCATNUMLEVEL,
  SETREPORTCATNUMLEVELERROR,
  SETREPORTBYTYPE,
  SETREPORTBYTYPEERROR,
  SETREPORTBYDAY,
  SETREPORTBYDAYERROR,
  SETREPORTBYLOCATION,
  SETREPORTBYLOCATIONERROR,
  SETCITY,
  SETCITYERROR
};
