import {
  LOADING,
  SETCATEGORY,
  SETCATEGORYERROR,
  SAVECATEGORY,
  SAVECATEGORYERROR,
  EDITCATEGORY,
  EDITCATEGORYERROR,
  ERRORTOKEN,
  SETOABTOKENERROR
} from "../constants.js";
import API from "../api";

const authRegext = (response, dispatch) => {
  if (response.status === 401) {
    dispatch({
      type: ERRORTOKEN,
      payload: null
    });
    dispatch({
      type: SETOABTOKENERROR,
      payload: null
    });
  }
};

const getCategories = () => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.get("/categories");

  authRegext(response, dispatch);

  if (response.status === 200) {
    dispatch({
      type: SETCATEGORY,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETCATEGORYERROR,
      payload: null
    });
  }
};

const saveCategory = (name, parent = null) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.post("/categories", {
    name,
    parent
  });

  authRegext(response, dispatch);

  if (response.status === 200) {
    dispatch(getCategories());
    dispatch({
      type: SAVECATEGORY,
      payload: response.data
    });
  } else {
    dispatch({
      type: SAVECATEGORYERROR,
      payload: false
    });
  }
};

const editCategory = (categoryId, name, parent) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.put(`/categories/${categoryId}`, {
    name,
    parent
  });

  authRegext(response, dispatch);

  if (response.status === 200) {
    dispatch(getCategories());
    dispatch({
      type: EDITCATEGORY,
      payload: response.data
    });
  } else {
    dispatch({
      type: EDITCATEGORYERROR,
      payload: false
    });
  }
};

export default {
  getCategories,
  saveCategory,
  editCategory
};
