import React from "react";
import PropTypes from "prop-types";

import style from "./index.module.css";

function Input({ placeholder, type, setValue = () => {} }) {
  return (
    <div className={style["input__holder"]}>
      <input
        className={style["input__field"]}
        type={type}
        placeholder={placeholder}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  setValue: PropTypes.func
};

Input.defaultProps = {
  type: "text"
};

export default Input;
