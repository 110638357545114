import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { Header } from "../../modules";
import { ButtonLink } from "../../components";

import { Auth } from "../../utils";

import { actionComplaint } from "../../redux/actions";

class ProblemaRegistrado extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history } = props;

    if (!Auth.checkAuth(tokenData, 1)) {
      history.replace("/");
    }
  }

  componentDidUpdate(prevProps) {
    const { complaintSaved, history } = this.props;

    if (complaintSaved === null) {
      history.replace("/inicio");
    }
  }

  back2home = () => {
    this.props.clearComplaint();
  };

  render() {
    const { userInfo } = this.props;

    const title = "PROBLEMA\nREGISTRADO";
    return (
      <>
        <Header title={title} />
        <p className={style["content"]}>
          Prezado Dr. {userInfo.name},
          <br />
          <br />
          Agradecemos o registro realizado.
          <br />
          <br />
          Sua contribuição auxiliará a OAB SP na implementação de ações
          institucionais visando solucionar o problema narrado.
          <br />
          <br />
          Contudo, havendo interesse na instauração de procedimento específico
          perante a Comissão de Direitos e Prerrogativa utilize o nosso e-mail
          institucional:
          <br />
          <a
            href="mailto:prerrogativas@oabsp.org.br"
            target="_blank"
            rel="noopener"
          >
            prerrogativas@oabsp.org.br
          </a>
        </p>
        <ButtonLink action={this.back2home}>Voltar ao Início</ButtonLink>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    complaintSaved: state.complaint.saved,
    userInfo: state.user.info
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators({ ...actionComplaint }, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(ProblemaRegistrado));
