import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { Welcome } from "../../modules";
import {
  Input,
  ButtonLink,
  Select,
  Textarea,
  ButtonBack
} from "../../components";

import { Auth } from "../../utils";

import {
  actionProblem,
  actionCategory,
  actionComplaint,
  actionCity
} from "../../redux/actions";

class RelatarProblema extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history, categoryList, problemList, cityList } = props;

    if (!Auth.checkAuth(tokenData, 1)) {
      history.replace("/");
    }

    if (categoryList === null) {
      props.getCategories();
    }

    if (problemList === null) {
      props.getProblems();
    }

    if (cityList === null) {
      props.getCityList();
    }

    this.state = {
      category: null,
      type: null,
      problem: null,
      city: null,
      problemOther: null,
      description: null
    };

    this.allCategory = false;
  }

  componentDidUpdate(prevProps) {
    const { complaintSaved, history } = this.props;

    if (complaintSaved === true) {
      history.replace("/problemaRegistrado");
    } else if (complaintSaved === false) {
      history.replace("/erro");
    }
  }

  getCategoryByParent = parentId => {
    const { categoryList } = this.props;
    const categories = [];

    if (categoryList) {
      categoryList.forEach(item => {
        const itemParentId = item.parent ? item.parent : "0";

        if (itemParentId === parentId) {
          categories.push(item);
        }
      });
    }

    return categories;
  };

  getCategoryById = categoryId => {
    const { categoryList } = this.props;
    let categoryItem = null;

    if (categoryList) {
      categoryItem = categoryList.find(item => item.categoryId === categoryId);
    }

    return categoryItem;
  };

  getSelectCategoryElm = (level, data, value = "") => {
    const options = data.map(item => ({
      name: item.categoryId,
      value: item.name
    }));

    const placeholder =
      level === 0 ? "Selecionar Órgão" : "Selecione uma opção";

    return (
      <Select
        key={Math.random()}
        options={options}
        placeholder={placeholder}
        defaultValue={value}
        getValue={value => this.setState({ category: value })}
      ></Select>
    );
  };

  getCategorySelects = () => {
    const { category } = this.state;

    const categoryId2NextSelect = category ? category : "0";

    const nextSelect = this.getCategoryByParent(categoryId2NextSelect);

    const selectElm = [];

    let level = 0;

    const getSelectByLevel = currentCategory => {
      const categoryIdItem = this.getCategoryById(currentCategory);
      if (categoryIdItem) {
        const categoryIdItemParent = categoryIdItem.parent
          ? categoryIdItem.parent
          : "0";

        const selectListItem = this.getCategoryByParent(categoryIdItemParent);

        selectElm.unshift(
          this.getSelectCategoryElm(
            level,
            selectListItem,
            categoryIdItem.categoryId
          )
        );

        const categoryIdParentItem = this.getCategoryById(categoryIdItemParent);

        level++;
        if (categoryIdParentItem) {
          getSelectByLevel(categoryIdItemParent);
        }
      }
    };

    getSelectByLevel(category);

    if (nextSelect.length) {
      selectElm.push(this.getSelectCategoryElm(level, nextSelect));
      this.allCategory = false;
    } else {
      this.allCategory = true;
    }

    return selectElm;
  };

  saveReclamacao = () => {
    const { category, description, city } = this.state;
    const { userInfo } = this.props;

    if (this.allCategory && description && city) {
      this.props.saveComplaint({
        name: userInfo.name,
        noab: userInfo.noab,
        type: "",
        categoryId: category,
        problem: "",
        city,
        description
      });
    }
  };

  render() {
    const { cityList, userInfo } = this.props;
    const { city } = this.state;

    const categorySelect = this.getCategorySelects();

    const title = (
      <>
        Olá,
        <br />
        {userInfo.name}
        <br />
        <br />
        QUAL O PROBLEMA?
      </>
    );

    const location = cityList ? (
      <Select
        key={Math.random()}
        options={cityList.map(item => ({
          name: item,
          value: item
        }))}
        placeholder="Localidade"
        defaultValue={city}
        getValue={value => this.setState({ city: value })}
      />
    ) : null;

    return (
      <>
        <Welcome title={title} />
        {categorySelect}

        {location}

        <Textarea
          placeholder="Observações"
          getValue={value => this.setState({ description: value })}
        ></Textarea>
        <ButtonLink action={this.saveReclamacao} children="Enviar"></ButtonLink>
        <ButtonBack link="/inicio" children="Voltar ao início"></ButtonBack>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    categoryList: state.category.list,
    problemList: state.problem.list,
    cityList: state.city.list,
    complaintSaved: state.complaint.saved,
    userInfo: state.user.info
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators(
    { ...actionProblem, ...actionCategory, ...actionComplaint, ...actionCity },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(RelatarProblema));
