import React from "react";
import PropTypes from "prop-types";

import style from "./index.module.css";

function ButtonQuestion({ children, link }) {
  return <button className={style["button__question"]} rel="noopener">{children}</button>;
}

ButtonQuestion.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ButtonQuestion;