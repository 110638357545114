import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import style from "./index.module.css";
import { Input, Button } from "../../components";

import { actionLogin } from "../../redux/actions";

class FormLogin extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      pass: null
    };
  }

  setValue2State = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  submitForm = () => {
    const { getToken, getTokenOAB, admin } = this.props;
    const { user, pass } = this.state;

    if (admin) {
      getToken(user, pass);
    } else {
      getTokenOAB(user, pass);
    }
  };

  render() {
    const { submitLabel, admin } = this.props;

    const recoveryElm = !admin ? (
      <a href="/recuperarSenha" className={style["recover__password"]}>
        Recuperar senha
      </a>
    ) : null;

    const inputUserName = admin ? "Email" : "Número OAB";

    return (
      <>
        <div>
          <Input
            type="text"
            placeholder={inputUserName}
            setValue={value => this.setValue2State("user", value)}
          />
          <Input
            type="password"
            placeholder="Senha"
            setValue={value => this.setValue2State("pass", value)}
          />
          {recoveryElm}
          <Button action={this.submitForm}>{submitLabel}</Button>
        </div>
      </>
    );
  }
}

FormLogin.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  admin: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators({ ...actionLogin }, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(FormLogin);
