import { combineReducers } from "redux";

import general from "./general";
import login from "./login";
import category from "./category";
import problem from "./problem";
import complaint from "./complaint";
import user from "./user";
import reports from "./reports";
import city from "./city";

const appReducer = combineReducers({
  general,
  login,
  category,
  problem,
  complaint,
  user,
  reports,
  city
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
