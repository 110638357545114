import { LOADING, SETCITY, SETCITYERROR } from "../constants.js";
import API from "../api";

const getCityList = () => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const reduxState = getState();
  const token = reduxState.login.tokenData
    ? reduxState.login.tokenData.token
    : null;

  const api = new API(token);

  const response = await api.get("/cities");

  if (response.status === 200) {
    dispatch({
      type: SETCITY,
      payload: response.data
    });
  } else {
    dispatch({
      type: SETCITYERROR,
      payload: null
    });
  }
};

export default {
  getCityList
};
