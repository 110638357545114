import React, { PureComponent } from "react";

import style from "./index.module.css";

import { Header } from "../../modules";
import { ButtonLink, ButtonBack } from "../../components";

class RecuperarSenha extends PureComponent {
  render() {
    const title = "RECUPERAR\nSENHA";
    return (
      <>
        <Header title={title} />
        <p className={style["content"]}>
          Este aplicativo utiliza o login OAB. Para recuperar a sua senha,
          acesse o site e siga as orientações.
        </p>
        <ButtonLink
          link="https://www2.oabsp.org.br/asp/dotnet/LoginSite/SolicitarReCadastramentoSenhaMain.aspx"
          children="Recuperar Senha"
        ></ButtonLink>
        <ButtonBack link="/" children="Faça seu login"></ButtonBack>
      </>
    );
  }
}

export default RecuperarSenha;
