import {
  SAVECOMPLAINT,
  SAVECOMPLAINTERROR,
  SETCOMPLAINTBYID,
  SETCOMPLAINTBYIDERROR,
  CLEARCOMPLAINT,
  SETCOMPLAINT,
  SETCOMPLAINTERROR,
  CLEARCOMPLAINTBYID
} from "../constants.js";

import { combineReducers } from "redux";

const saved = (state = null, { type, payload }) => {
  switch (type) {
    case SAVECOMPLAINT:
      return true;
    case SAVECOMPLAINTERROR:
      return false;
    case CLEARCOMPLAINT:
      return null;
    default:
      return state;
  }
};

const list = (state = null, { type, payload }) => {
  switch (type) {
    case SETCOMPLAINT:
    case SETCOMPLAINTERROR:
      return payload;
    default:
      return state;
  }
};

const item = (state = {}, { type, payload }) => {
  switch (type) {
    case SETCOMPLAINTBYID:
    case SETCOMPLAINTBYIDERROR:
      return payload;
    case CLEARCOMPLAINTBYID:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ saved, list, item });
