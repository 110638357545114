import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import style from "./index.module.css";
import { Input, Button } from "../../components";

class FormLoginAdmin extends PureComponent {
  render() {
    const { submitLabel } = this.props;
    return (
      <>
        <div>
          <Input type="text" placeholder="E-mail" />
          <Input type="password" placeholder="Senha" />
          <br />
          <Button>{submitLabel}</Button>
        </div>
      </>
    );
  }
}

FormLoginAdmin.propTypes = {
  submitLabel: PropTypes.string.isRequired
};

export default FormLoginAdmin;
