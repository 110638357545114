import { SETOABTOKEN, SETOABTOKENERROR } from "../constants.js";

import { combineReducers } from "redux";

const info = (state = null, { type, payload }) => {
  switch (type) {
    case SETOABTOKEN:
      return {
        name: payload.name,
        noab: payload.noab
      };
    case SETOABTOKENERROR:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({ info });
