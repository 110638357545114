import React from "react";
// import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import style from "./index.module.css";

function Sidebar({ title }) {
  return (
    <>
      <div className={style["sidebar"]}>
        <ul>
          <li>
            <Link to="/admin/inicio">Início</Link>
          </li>
          <li>
            <Link to="/admin/categorias">Cadastrar Órgãos</Link>
          </li>
          {/*<li>
            <Link to="/admin/problemas">Cadastrar Problema</Link>
          </li>*/}
          <li>
            <Link to="/admin/relatorios">Relatório</Link>
          </li>
        </ul>
      </div>
    </>
  );
}
export default Sidebar;
