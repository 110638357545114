import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";
import { ReactComponent as Logo } from "./logo-oab.svg";

import { actionLogin } from "../../redux/actions";

import { Auth } from "../../utils";

class HeaderAdmin extends PureComponent {
  constructor(props) {
    super(props);

    this.checkLogin();
  }

  componentDidUpdate(prevProps) {
    this.checkLogin();
  }

  logout = () => {
    this.props.clearToken();
  };

  checkLogin = () => {
    const { tokenData, history } = this.props;

    if (!Auth.checkAuth(tokenData)) {
      history.replace("/admin");
    }
  };

  render() {
    return (
      <>
        <div className={style["header"]}>
          <Logo />
          <h2 className={style["header__title"]}>
            <a href="#" onClick={this.logout}>
              Clique aqui
            </a>{" "}
            para desconectar.
          </h2>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData
  };
};

const mapDisptachToProps = dispatch =>
  bindActionCreators({ ...actionLogin }, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(HeaderAdmin));
