import React from "react";
import PropTypes from "prop-types";

import style from "./index.module.css";
import { ReactComponent as Logo } from './logo-oab.svg';

function Header({ title }) {
  return (
      <>
      	<div className={style["header"]}>
        	<Logo />
        </div>
        <h2 className={style["header__title"]}>{title}</h2>
      </>
    );
}

Header.propTypes = {
  title: PropTypes.string
};

Header.defaultProps = {
  title: ""
};

export default Header;
