import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import style from "./index.module.css";

class Select extends PureComponent {
  constructor(props) {
    super(props);

    const value = props.defaultValue ? props.defaultValue : "";

    this.state = {
      value
    };
  }

  setValue = e => {
    const { getValue } = this.props;

    const valueSelected = e.target.value;

    if (getValue) {
      getValue(valueSelected);
    }

    this.setState({
      value: valueSelected
    });
  };

  render() {
    const { options = [], placeholder } = this.props;
    const { value } = this.state;

    const optionsElm = options.map((item, index) => {
      return (
        <option key={index} value={item.name}>
          {item.value}
        </option>
      );
    });

    if (placeholder) {
      optionsElm.unshift(
        <option key={-1} value="">
          {placeholder}
        </option>
      );
    }
    return (
      <div className={style["input__holder"]}>
        <select
          className={style["input__field"]}
          value={value}
          onChange={this.setValue}
        >
          {optionsElm}
        </select>
      </div>
    );
  }
}

export default Select;
