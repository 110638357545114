import React from "react";
import style from "./App.module.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  Login,
  RecuperarSenha,
  Inicio,
  ProblemaRegistrado,
  Erro,
  RelatarProblema,
  AdminLogin,
  AdminIndex,
  AdminCategory,
  AdminProblem,
  AdminDetails,
  AdminReport
} from "./pages";

const UserContainer = ({ children }) => {
  return (
    <div className={style["app"]}>
      <div className={style["app__holder"]}>{children}</div>
    </div>
  );
};

const AdminContainer = ({ children }) => {
  return (
    <div className={style["admin"]}>
      <div className={style["admin__holder"]}>{children}</div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <UserContainer>
            <Login />
          </UserContainer>
        </Route>
        <Route exact path="/recuperarSenha">
          <UserContainer>
            <RecuperarSenha />
          </UserContainer>
        </Route>
        <Route exact path="/inicio">
          <UserContainer>
            <Inicio />
          </UserContainer>
        </Route>
        <Route exact path="/problemaRegistrado">
          <UserContainer>
            <ProblemaRegistrado />
          </UserContainer>
        </Route>
        <Route exact path="/relatarProblema">
          <UserContainer>
            <RelatarProblema />
          </UserContainer>
        </Route>
        <Route exact path="/erro">
          <UserContainer>
            <Erro />
          </UserContainer>
        </Route>

        <Route exact path="/admin">
          <AdminContainer>
            <AdminLogin />
          </AdminContainer>
        </Route>
        <Route exact path="/admin/inicio">
          <AdminContainer>
            <AdminIndex />
          </AdminContainer>
        </Route>
        <Route exact path="/admin/categorias">
          <AdminContainer>
            <AdminCategory />
          </AdminContainer>
        </Route>
        <Route exact path="/admin/relatorios">
          <AdminContainer>
            <AdminReport />
          </AdminContainer>
        </Route>
        <Route exact path="/admin/problemas">
          <AdminContainer>
            <AdminProblem />
          </AdminContainer>
        </Route>
        <Route exact path="/admin/problemas/:complaintId">
          <AdminContainer>
            <AdminDetails />
          </AdminContainer>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
