import {
  SETTOKEN,
  ERRORTOKEN,
  SETOABTOKEN,
  SETOABTOKENERROR
} from "../constants.js";

import { combineReducers } from "redux";

const tokenData = (state = null, { type, payload }) => {
  switch (type) {
    case SETOABTOKEN:
      return {
        token: payload.token,
        level: payload.level
      };
    case SETOABTOKENERROR:
    case SETTOKEN:
    case ERRORTOKEN:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({ tokenData });
