import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import style from "./index.module.css";

import { Welcome } from "../../modules";
import { ButtonLink, ButtonQuestion } from "../../components";

import { Auth } from "../../utils";

class Inicio extends PureComponent {
  constructor(props) {
    super(props);

    const { tokenData, history } = props;

    if (!Auth.checkAuth(tokenData, 1)) {
      history.replace("/");
    }
  }

  render() {
    const { userInfo } = this.props;

    const title = "Olá,\n" + userInfo.name;
    return (
      <>
        <div className={style["holder__general"]}>
          <Welcome title={title} />
          <p className={style["page__content"]}>
            Este app tem a finalidade de identificar e mapear as dificuldades encontradas pela Advocacia Paulista no curso do seu exercício profissional perante órgãos da administração pública e do poder Judiciário, de modo a permitir  a atuação institucional da OAB SP.
          </p>
          <ButtonQuestion children="Sobre o app"></ButtonQuestion>
        </div>
        <div className={style["buttonflex"]}>
          <p className={style["content"]}>
            Toque no botão abaixo para relatar um problema.
          </p>
          <ButtonLink
            link="/relatarProblema"
            children="Relatar um problema"
          ></ButtonLink>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenData: state.login.tokenData,
    userInfo: state.user.info
  };
};

const mapDisptachToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(Inicio));
