class Type {
  constructor() {
    this.types = [
      {
        name: "falha",
        value: "Falha de Atendimento"
      },
      {
        name: "violacao",
        value: "Violação de Prerrogativa"
      }
    ];
  }

  getNameById(id) {
    const typeItem = this.types.find(item => item.name === id);

    let typeName = "";

    if (typeItem) {
      typeName = typeItem.value;
    }

    return typeName;
  }

  getList() {
    return this.types;
  }
}

export default new Type();
