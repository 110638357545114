import React from "react";
import PropTypes from "prop-types";

import style from "./index.module.css";
import { ReactComponent as Logo } from "./logo-oab.svg";
import { ButtonQuestion } from "../../components";

function Welcome({ title }) {
  return (
    <>
      <div className={style["header"]}>
        <Logo />
      </div>
      <div className={style["title__holder"]}>
        <h2 className={style["header__title"]}>{title}</h2>
      </div>
    </>
  );
}

Welcome.propTypes = {
  title: PropTypes.any
};

Welcome.defaultProps = {
  title: ""
};

export default Welcome;
