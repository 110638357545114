import { SETCATEGORY, SETCATEGORYERROR } from "../constants.js";

import { combineReducers } from "redux";

const list = (state = null, { type, payload }) => {
  switch (type) {
    case SETCATEGORY:
    case SETCATEGORYERROR:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({ list });
